import { prepareDatetimes } from '@/api/helpers'

export async function searchMentors (data) {
  return apiFetch('/mentors', { params: data }).then(prepareDatetimes)
}

export function getMentorsMeta () {
  return apiFetch('/mentors/meta')
}

export function getMyMentors () {
  return apiFetch('/mentors/my').then(prepareDatetimes)
}

export function getMyMentorProfile (id) {
  return apiFetch(`/mentors/my/${id}`).then(prepareDatetimes)
}

export function bookMentor (id, data) {
  return apiFetch(`/mentors/${id}/book`, { method: 'POST', body: data })
}

export function getMyMenties () {
  return apiFetch('/menties').then(prepareDatetimes)
}

export function getMentiProfile (id) {
  return apiFetch(`/menties/${id}`).then(prepareDatetimes)
}

export function getMentiMeet (id) {
  return apiFetch(`/menties/meet/${id}`).then(prepareDatetimes)
}

export function getMentorMeet (id) {
  return apiFetch(`/mentors/meet/${id}`).then(prepareDatetimes)
}

export function closeMeet (id) {
  return apiFetch(`/mentors/meet/${id}/close`).then(prepareDatetimes)
}
